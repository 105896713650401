import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
// slices
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import settingsReducer from './slices/settings';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer
});

export { rootPersistConfig, rootReducer };
