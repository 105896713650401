import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { createFirestoreInstance } from 'redux-firestore';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import { store } from '../redux/store';
import { firebaseConfig } from '../config';

// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../views/authentication/Login';

// ----------------------------------------------------------------------
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.storage();
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    useFirestoreForStorageMeta: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <ReactReduxFirebaseProvider {...rrfProps}>{children}</ReactReduxFirebaseProvider>;
}
